import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
import { FormatServerDate } from "./service.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

export async function getVouchers({
    code, usedBy, expiryDateType, expiryDateStart, expiryDateEnd, isRedeemed,
    page
}) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/voucher-list`,
            params: {
                code: code || '',
                usedBy: usedBy|| '',
                expiryDateType: expiryDateType || '',
                expiryDateStart: FormatServerDate(expiryDateStart) || '',
                expiryDateEnd: expiryDateEnd || '',
                isRedeemed: isRedeemed || '',
                page: page || 0
            }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }
        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createVoucher(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/voucher-create`,
            data: { ...body }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}
