export const operationalHours = [
    {
        day: "Monday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Tuesday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Wednesday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Thursday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Friday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Saturday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Sunday",
        isActive: true,
        operationalHours: {
            start: "06:00",
            end: "23:00"
        },
        hours: []
    },
]

export const dayOptions = [
    {
        label: "Monday",
        value: "Mon",
        number: 1,
    },
    {
        label: "Tuesday",
        value: "Tue",
        number: 2,
    },
    {
        label: "Wednesday",
        value: "Wed",
        number: 3,
    },
    {
        label: "Thursday",
        value: "Thu",
        number: 4,
    },
    {
        label: "Friday",
        value: "Fri",
        number: 5,
    },
    {
        label: "Saturday",
        value: "Sat",
        number: 6,
    },
    {
        label: "Sunday",
        value: "Sun",
        number: 0,
    },
]

export const timeOptions = [
    {
        label: "00:00",
        value: "00:00"
    },
    {
        label: "01:00",
        value: "01:00"
    },
    {
        label: "02:00",
        value: "02:00"
    },
    {
        label: "03:00",
        value: "03:00"
    },
    {
        label: "04:00",
        value: "04:00"
    },
    {
        label: "05:00",
        value: "05:00"
    },
    {
        label: "06:00",
        value: "06:00"
    },
    {
        label: "07:00",
        value: "07:00"
    },
    {
        label: "08:00",
        value: "08:00"
    },
    {
        label: "09:00",
        value: "09:00"
    },
    {
        label: "10:00",
        value: "10:00"
    },
    {
        label: "11:00",
        value: "11:00"
    },
    {
        label: "12:00",
        value: "12:00"
    },
    {
        label: "13:00",
        value: "13:00"
    },
    {
        label: "14:00",
        value: "14:00"
    },
    {
        label: "15:00",
        value: "15:00"
    },
    {
        label: "16:00",
        value: "16:00"
    },
    {
        label: "17:00",
        value: "17:00"
    },
    {
        label: "18:00",
        value: "18:00"
    },
    {
        label: "19:00",
        value: "19:00"
    },
    {
        label: "20:00",
        value: "20:00"
    },
    {
        label: "21:00",
        value: "21:00"
    },
    {
        label: "22:00",
        value: "22:00"
    },
    {
        label: "23:00",
        value: "23:00"
    }
]

export const courtTypeOption = [
    {
        label: "Indoor",
        value: "indoor"
    },
    {
        label: "Outdoor",
        value: "outdoor"
    },
    {
        label: "Hitting Room",
        value: "hitting_room"
    },
]

export const courtTypePackageOption = [
    {
        label: "All Court",
        value: "all_court"
    },
    {
        label: "Outdoor",
        value: "outdoor"
    },
    {
        label: "Hitting Room",
        value: "hitting_room"
    },
]

export const availableMonthOption = [
    // {
    //     label: "Month + 0",
    //     value: 0
    // },
    {
        label: "Month + 1",
        value: 1
    },
    {
        label: "Month + 2",
        value: 2
    },
    {
        label: "Month + 3",
        value: 3
    },
    // {
    //     label: "Month + 4",
    //     value: 4
    // },
    // {
    //     label: "Month + 5",
    //     value: 5
    // },
    // {
    //     label: "Month + 6",
    //     value: 6
    // },
]

export const validityMonthOption = [
    {
        label: "3 Month",
        value: 3
    },
    {
        label: "4 Month",
        value: 4
    },
    {
        label: "5 Month",
        value: 5
    },
    {
        label: "6 Month",
        value: 6
    },
    {
        label: "7 Month",
        value: 7
    },
    {
        label: "8 Month",
        value: 8
    },
    {
        label: "9 Month",
        value: 9
    },
    {
        label: "10 Month",
        value: 10
    },
    {
        label: "11 Month",
        value: 11
    },
    {
        label: "12 Month",
        value: 12
    },
]

export const paymentType = [
    {
        label: "Virtual Account (VA)",
        value: "VA"
    },
    {
        label: "Bank Transfer",
        value: "TF"
    },
]

export const providerOption = [
    {
        label: "Virtual Account (VA)",
        value: "va"
    },
    {
        label: "STC-HO",
        value: "stc-ho"
    },
    {
        label: "QRIS",
        value: "qris"
    },
]

export const discountType = [
    {
        label: "Percentage (%)",
        value: "percentage"
    },
    {
        label: "Nominal",
        value: "nominal"
    },
]

export const AddonTypeOption = [
    {
        label: 'Service',
        value: 'service'
    },
    {
        label: 'Rent',
        value: 'rent'
    },
    {
        label: 'Buy',
        value: 'sell'
    },
]

export const roleOption = [
    { value: 'superadmin', label: "Super Admin" },
    { value: 'admin', label: "Admin" },
    { value: 'marketing', label: "Marketing" },
    { value: 'management', label: "Management" },
    { value: 'bod', label: "Board of Directors" }
]

export const orderType = (e) => {
    if (e === "value_pack") {
        return "Value Pack"
    } if (e === "booking") {
        return "Court Booking"
    } if (e === "rent") {
        return "Rent"
    } if (e === "sell") {
        return "Buy"
    } if (e === "service") {
        return "Service"
    } if (e === "class") {
        return "Class"
    } if (e === "add_on") {
        return "Add On"
    } else { return "-"}
}

export const orderTypeOption = [
    { value: 'value_pack', label: "Value Pack" },
    { value: 'booking', label: "Court Booking" },
    { value: 'service', label: "Service" },
    { value: 'class', label: "Class" },
    { value: 'rent', label: "Rent" },
    { value: 'sell', label: "Buy" },
]

export const orderStatusOption = [
    { value: "", label: "All" },
    { value: "waiting_payment", label: "Waiting Payment" },
    { value: "payment_failed", label: "Payment Failed" },
    { value: "paid", label: "Paid" },
    { value: "rent", label: "Rent" },
    // { value: "counter_pickup", label: "Counter Pickup" },
    { value: "completed", label: "Completed" },
]

export const userStatus = [
    { value: 'regular', label: "Regular" },
    { value: 'superuser', label: "Super User" },
    { value: 'coach', label: "Coach" },
    { value: 'rally_partner', label: "Rally Partner" },
    { value: 'ball_boy', label: "Ballboy" },
    { value: 'subscriber', label: "Subscriber" },
    { value: 'counter', label: "Counter" }
]

export const optionStatus = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
]

export const trueFalseOptions = [
    { label: "All", value: '' },
    { label: "Active", value: 'true' },
    { label: "Not Active", value: 'false' }
]

export const advancedSchedule = [
    { value: 1, label: "Month + 1" },
    { value: 2, label: "Month + 2" },
    { value: 3, label: "Month + 3" },
    { value: 6, label: "6 Month" },
    { value: 12, label: "12 Month" },
    { value: 9999, label: "All" },
]

export const userStatusOptions = [
    { value: 'rally_partner', label: "Rally Partner" },
    { value: 'ball_boy', label: "Ballboy" }
]

export const userStatusObj = {
    'rally_partner': "Rally Partner",
    'ball_boy': "Ballboy"
}

export const DBCR = [
    { value: 'DB', label: "DB" },
    { value: 'CR', label: "CR" }
]

export const genderOptions = [
    { value: 'male', label: "Male" },
    { value: 'female', label: "Female" }
]

export const availableOption=[
    { value: "true", label: "Available" },
    { value: "false", label: "Not Available" }
]

export const activeOption=[
    { value: 'all', label: "All" },
    { value: 'true', label: "Active" },
    { value: 'false', label: "Inactive" }
]

export const coachingTypeOption=[
    {value:'coaching', label: 'Coaching'},
    {value:'sparring', label: 'Sparring'}
]

export const verifiedOption=[
    {value:'verified', label: 'Verified'},
    {value:'pending', label:'Verification Pending'}
]

export const color = {
    purple: "#663399",
    orange: "#f79256",
    darkorange: "#623A22",
    darkgreen: "#3E675B",
    blue: "#1d4e89",
    grey: "#9da6ab",
    red: "#ff2115",
    blueIndoor_1: "#025597",
    blueIndoor_2: "#0198E1",
    primary: "#025597",
    green: "#4DBD74",
    pink: "#E35480",
}

export const periodOptions = [
    {
        label: "Order Time",
        value: "order_time"
    },
    {
        label: "Session Date",
        value: "session_date"
    },
    // {
    //     label: "Complete Date",
    //     value: "complete_date"
    // }
]

export const listMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

export const chartColor = {
    backgroundColor: '#2196f3',
    borderColor: '#2196f3',
    borderWidth: 1
}