import React, { useState, useEffect } from "react"

import { Button, Grid } from "@mui/material"
import CourtListComponent from "./courtListComponent"
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import DropZoneComponent from "components/DropzoneComponent"
import PriceListComponent from "./priceListComponent"

function AddCourtComponent ({   
    setCourt,
    courtInfo = [],
    priceList = [],
    formImages
}) {

    // if total court tidak sama dengan court perlu untuk alert
    const [ totalCourt, setTotalCourt ] = useState([])
    const [ priceCourt, setPriceCourt ] = useState([])
    const [ excludePrice, setExcludePrice ] = useState([])
    const [ layoutImages, setLayoutImages ] = useState(formImages)

    useEffect(() => {
        setTotalCourt(courtInfo)
    }, [courtInfo])

    useEffect(() => {
        setPriceCourt(priceList)
    }, [priceList])

    useEffect(() => {
        setLayoutImages(formImages)
    }, [formImages])

    useEffect(() => {
        setCourt({
            layoutPhotos: layoutImages
        })
    }, [layoutImages])

    const addCourt = () => {
        let newCourt = {
            _id: null,
            name: "",
            type: "",
            price: ""
        }
        setTotalCourt(prev => ([
            ...prev,
            newCourt
        ]))
    }

    const deleteCourt = async () => {

    }

    const saveCourt = (e, index) => {
        let currData = totalCourt[index];
        currData = {
            ...currData,
            ...e,
            _id: currData._id ? currData._id : Date.now()
        }
        let newOps = totalCourt;
        newOps.splice(index, 1, currData)
        setTotalCourt(prev => (newOps))
        setCourt({ courts: newOps })
        // setReload(Date.now())
    }
    
    const deleteLayoutImages = (index) => {
        let currImages = layoutImages
        currImages.splice(index, 1); 
        setLayoutImages(prev => ([...currImages]))
    }


    const savePrice = (e, index) => {
        console.log('savePrice', e, index)
        let currData = priceCourt[index];
        currData = {
            ...currData,
            ...e,
            _id: currData._id ? currData._id : Date.now()
        }
        let newOps = priceCourt;
        newOps.splice(index, 1, currData)
        setCourt({ prices: newOps })
        // setReload(Date.now())
    }

    const createPrice = () => {
        let newCourt = {
            _id: null,
            name: "",
            type: "",
            price: ""
        }
        setPriceCourt(prev => ([
            ...prev,
            newCourt
        ]))
    }

    const createExcludePrice = () => {
        let newCourt = {
            _id: null,
            exclude: true,
            name: "",
            type: "",
            price: ""
        }
        setPriceCourt(prev => ([
            ...prev,
            newCourt
        ]))
    }

    const deletePrice = async () => {

    }


    return (
        <Grid container xs={12} sx={{
            p: 2,
            pt: 0,
         }}>
            <Grid item xs={12} md={12} sx={{
                p: 1
            }}>
                <TCTitleLabel>Court</TCTitleLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={4} sx={{
                p: 1
            }}>
                <TCLabel>Upload Court Layout</TCLabel>
                <DropZoneComponent className="drop-zone"
                file={layoutImages}
                uploadName='layoutImage'
                isMultiple={true}
                onDrop={(files, event) => {
                      let currImages = layoutImages ? layoutImages : []
                      for (let i = 0; i < files.length; i ++) {
                          currImages = currImages.concat(files[i])
                      }
                      setLayoutImages(currImages)
                }}
                >
                    <div className='dropzone mb-0' style={{
                        textAlign: 'center',
                        minHeight: '150px'
                    }}>
                        <div style={{ padding: 10, alignItems: 'center'}}>
                          <label>
                              <i className='fa fa-upload mr-1' />
                              Drop image to upload
                          </label>
                          <br />
                          <label
                              htmlFor={"layoutImage"}
                              style={{ cursor: 'pointer' }}
                              className='dropzone-child mb-0'>
                              or Click to Browse
                          </label>
                          <div style={{overflowX: 'auto'}}>
                          {
                              (layoutImages && layoutImages.length > 0) &&
                              <div style={{ textAlign: 'left' }}>
                                  Uploaded file : 
                              { 
                                  layoutImages.map( (value, index) => {
                                      return (
                                          <div key={`${value.name}${index}`} className='d-flex mb-1' style={{ textAlign: 'left' }}>
                                              <img src={(!value.name && value.includes("https")) ? value : URL.createObjectURL(value)} style={{minWidth: '100px', maxHeight: '50px', objectFit: 'contain'}}
                                                  onClick={() => {
                                                      // viewImageDetail({
                                                      //     open: true,
                                                      //     url: false,
                                                      //     file: value
                                                      // })
                                                  }}
                                              />
                                              <label className='mt-1 mb-0 ml-1' style={{alignItems: 'flex-end'}}>
                                                  {value.name}
                                                  <span style={{marginLeft: 5, color: 'gray', cursor: 'pointer'}} onClick={() => 
                                                    deleteLayoutImages(index)
                                                  }>X</span>
                                                  {/* <i className="ml-2 fa fa-times-circle" style={{ color: 'gray', cursor: 'pointer' }} onClick={() => {
                                                      deleteCourtImages(value)
                                                  }} /> */}
                                              </label>
                                          </div>
                                      )
                                  })
                              }
                              </div>
                          }
                          </div>
                        </div>
                    </div>
                </DropZoneComponent>
            </Grid>
            <Grid item xs={12} md={12} sx={{
                p: 1
            }}>
                <TCLabel>Total Court {totalCourt.length}</TCLabel>
            </Grid>
            <Grid container item xs={12} md={12} sx={{
                p: 1
            }}>
                <Grid xs={12} md={12}>
                    <Button variant='contained' sx={{
                        mb: 1
                    }} onClick={() => {
                        addCourt()
                    }}>Add Court</Button>
                </Grid>
                {
                    totalCourt.map((value, index) => {
                        return  <Grid item xs={12} md={4} style={{padding: 4}}>
                            <CourtListComponent data={value} index={index} saveCourt={saveCourt}/>
                        </Grid>
                    })
                }
                <Grid item xs={12} md={12} sx={{
                    mt: 2,
                }}>
                    <TCTitleLabel>Sessions Price</TCTitleLabel>
                </Grid>
                {
                    totalCourt.length > 0 &&
                    <Grid container xs={12} md={12} pt={2} spacing={2}>
                        <Grid item>
                            <Button variant='contained' sx={{
                                mb: 1
                            }} onClick={() => {
                                createPrice()
                            }}>Add Session Price</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' sx={{
                                mb: 1
                            }} onClick={() => {
                                createExcludePrice()
                            }}>Add Exclude Price</Button>
                        </Grid>
                    </Grid>
                }
                {
                    priceCourt.map((value, index) => {
                        return  <Grid item xs={12} md={12} style={{padding: 1}}>
                            <PriceListComponent data={value} index={index} isException={value.exclude || value.dayString} savePrice={savePrice}/>
                        </Grid>
                    })
                }
            </Grid>
         </Grid>
    )
}

export default AddCourtComponent