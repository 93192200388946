import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DatePicker from "react-datepicker";
import moment from 'moment';

import { TCSelect } from './TopCourtInput';
import { TCLabel } from '../Label/TopCourtLabel';
import { Grid, TextField } from "@mui/material"

import "react-datepicker/dist/react-datepicker.css";

export const TypeSelectOption = [
    { value: "ib", label: "<>" },
    { value: "eq", label: "=" },
    { value: "lt", label: "<" },
    { value: "gt", label: ">" },
];

const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions()
// The TZ format I want everything to be displayed and submitted as
const apiTZ = 'Asia/Jakarta'

class DateRangeInput extends Component {

    // ---onChange
    // -date range type
    onChangeSelect = value => {
        const { onChangeSelect, startDate, endDate, onChangeEndDate } = this.props;
        if (value.value !== 'ib') {
            onChangeEndDate(null, this.getLoad(value, startDate, endDate));
        }
        onChangeSelect(value, this.getLoad(value, startDate, endDate));
    }

    // -start date
    onChangeStartDate = value => {
        const { onChangeStartDate, dateRangeType, endDate } = this.props;
        let startDate = moment(new Date(value)).tz(localTZ)
            // change TZ without changing date/time
            .tz(apiTZ, true)
            .format('YYYY-MM-DDT17:00:00.000')
        onChangeStartDate(JSON.parse(JSON.stringify(startDate)), this.getLoad(dateRangeType, value, endDate));
    }
    // -end date
    onChangeEndDate = value => {
        const { onChangeEndDate, dateRangeType, startDate } = this.props;
        // let endDate = value
        let endDate = null;
        if (value) {
            // endDate = moment(new Date(value)).format('YYYY-MM-DDT16:59:59.059')
            endDate = moment(new Date(value)).tz(localTZ)
                // change TZ without changing date/time
                .tz(apiTZ, true)
                .format('YYYY-MM-DDT16:59:59.059')
        }
        // console.log(new Date(value.getTime()) - new Date((value.getTimezoneOffset() * 60000)))
        onChangeEndDate(JSON.parse(JSON.stringify(endDate)), this.getLoad(dateRangeType, startDate, endDate));
    }
    // func helper
    getLoad = (dateRangeType, startDate, endDate) => {
        // helper for check range date
        let load = true;

        if (dateRangeType.value === 'ib') {
            // if one is empty
            if (!startDate || !endDate) { load = false; }
        } else {
            if (!startDate) { load = false; }
        }
        return load;
    }

    render() {
        // styles
        const styles = {
            flexLayout: {
                display: 'flex',
                flexFlow: 'wrap',
                margin: '0 -1.5px',
            },
            selectType: {
                dropdownIndicator: () => ({
                    display: 'none'
                }),
            },
            dateRangeType: {
                flex: '0.3 1 40px',
                padding: '0 1.5px',
            },
            startDate: {
                flex: '1 1 90px',
                padding: '0 1.5px',
            },
            endDate: {
                flex: '1 1 90px',
                padding: '0 1.5px',
            }
        };

        if (window.innerWidth > 1280) {
            styles.selectType = {
                dropdownIndicator: (base) => ({
                    ...base,
                    padding: 0,
                }),
            };
            styles.dateRangeType = {
                flex: '0.5 1 50px',
                padding: '0 1.5px',
            }
        }
        // props
        const {
            dateRangeType,
            showAlert,
            startDate,
            endDate,
            label,
            placeholderStart,
            placeholderEnd,
            customStyle,
            showLabel = false,
            disabledVariable,
            disabledStart = false,
            disabledEnd = false,
            disabledRange, // for show only one date picker,
            showTimeSelect,
            format,
            isInvalid,
            customSelectOption = [],
            popperPlacement,
            startDateMinDate = null,
            endDateMinDate = null,
            startDateMaxDate = null,
            popperModifiers
        } = this.props;

        return (
            <React.Fragment>
                {showLabel && (
                    <Grid container alignItems={'flex-end'} spacing={1}>
                        <Grid item>
                            <TCLabel style={customStyle ? customStyle.label ? customStyle.label : {color: "rgb(133, 133, 133)"} : {color: "rgb(133, 133, 133)"}}>{label}</TCLabel>
                        </Grid>
                        <Grid item sx={{ pt: '0px !important', pl: '2px !important' }}>
                            {showAlert && <TCLabel style={{ fontSize: 11, color: '#f44336' }}>{showAlert}</TCLabel>}
                        </Grid>
                    </Grid>
                )}
                {!disabledRange && (
                    <div style={styles.flexLayout}>
                        <div style={styles.dateRangeType}>
                            <TCSelect
                                name="selectType"
                                placeholder={"Type"}
                                styles={{
                                    indicatorSeparator: (base) => ({
                                        ...base,
                                        display: "none",
                                    }),
                                    dropdownIndicator: (base) => ({
                                        ...base,
                                        display: "none",
                                    }),
                                }}
                                disabled={disabledVariable}
                                isClearable={false}
                                value={dateRangeType}
                                options={customSelectOption.length > 0 ? customSelectOption : TypeSelectOption}
                                onChange={this.onChangeSelect}
                                isInvalid={isInvalid}
                            />
                        </div>
                        <div style={styles.startDate} title={placeholderStart}>
                            <DatePicker
                                name="startdate"
                                className={`form-control-rangefilter form-error ${isInvalid ? 'invalid' : ''} ${customStyle ? customStyle.datePickerClassName ? customStyle.datePickerClassName : "" : ""}`}
                                placeholderText={placeholderStart}
                                selected={startDate ? moment(startDate).tz(apiTZ).tz(localTZ, true).toDate() : null}
                                disabled={disabledStart}
                                showYearDropdown
                                showYearPicker={format == "yyyy" ? true : false}
                                showTimeSelect={showTimeSelect}
                                dateFormat={showTimeSelect ? "yyyy/MM/dd HH:mm" : format ? format : "yyyy/MM/dd"}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                scrollableYearDropdown
                                yearDropdownItemNumber={20}
                                autoComplete={"off"}
                                onChange={this.onChangeStartDate}
                                minDate={startDateMinDate}
                                popperPlacement={'bottom-start'}
                            />
                        </div>
                        <div style={styles.endDate} title={placeholderEnd}>
                            <DatePicker
                                name="enddate"
                                className={`form-control-rangefilter form-error ${isInvalid ? 'invalid' : ''} ${customStyle ? customStyle.datePickerClassName ? customStyle.datePickerClassName : "" : ""}`}
                                placeholderText={placeholderEnd}
                                selected={endDate ? moment(endDate).tz(apiTZ).tz(localTZ, true).toDate() : null}
                                showYearDropdown
                                showYearPicker={format == "yyyy" ? true : false}
                                showTimeSelect={showTimeSelect}
                                dateFormat={showTimeSelect ? "yyyy/MM/dd HH:mm" : format ? format : "yyyy/MM/dd"}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                scrollableYearDropdown
                                yearDropdownItemNumber={20}
                                autoComplete={"off"}
                                onChange={this.onChangeEndDate}
                                disabled={disabledEnd || dateRangeType.value !== 'ib'}
                                minDate={endDateMinDate}
                                popperPlacement={'bottom-end'}
                            />
                        </div>
                    </div>
                )}
                {disabledRange && (
                    <DatePicker
                        name="start"
                        className={`form-control-disabled-rangefilter`}
                        style={{ fontSize: 10, height: '30px !important' }}
                        placeholderText={placeholderStart}
                        selected={startDate ? moment(startDate).tz(apiTZ).tz(localTZ, true).toDate() : null}
                        showYearDropdown
                        showTimeSelect={showTimeSelect}
                        disabled={disabledStart}
                        dateFormat={showTimeSelect ? "yyyy/MM/dd HH:mm" : format ? format : "yyyy/MM/dd"}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        scrollableYearDropdown
                        yearDropdownItemNumber={20}
                        autoComplete={"off"}
                        onChange={this.onChangeStartDate}
                        minDate={startDateMinDate}
                        maxDate={startDateMaxDate}
                        popperPlacement={popperPlacement ? popperPlacement : 'bottom-start'}
                    // popperModifiers={popperModifiers ? popperModifiers : {}}
                    />
                )}
            </React.Fragment>
        )
    }
}
DateRangeInput.propTypes = {
    // value
    dateRangeType: PropTypes.object,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    // changes
    onChangeSelect: PropTypes.func,
    onChangeStartDate: PropTypes.func,
    onChangeEndDate: PropTypes.func,
    // title, text desc
    label: PropTypes.string,
    placeholderStart: PropTypes.string,
    placeholderEnd: PropTypes.string,
};
DateRangeInput.defaultProps = {
    label: 'Date',
    dateRangeType: TypeSelectOption[0],
    placeholderStart: 'Start Date',
    placeholderEnd: 'End Date',
    startDate: moment().startOf('month').toDate(),
    endDate: new Date(),
}
export default DateRangeInput;