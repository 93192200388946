import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { Box, Button, Card, Grid, Typography } from '@mui/material';

import { getPlaces } from 'services/placeServices';

import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import CourtFilter from './courtFilter';


function Courts({

}) {

  const navigate = useNavigate()
  const [rows, setRows] = useState([])
  const [filter, setFilter] = useState('')

  useEffect(() => {
    loadPlace()
  }, [filter])

  const loadPlace = async () => {
    let { data, error } = await getPlaces({
      queryParams: filter ? filter : ''
    })
    if (error) { console.log(error) }
    if (data) {
      setRows(data)
    }
  }

  const valueCustomator = (row) => {
    return ({
      date: <span className='span-link' onClick={() => { window.location.href = `/courts/edit/${row._id}` }}>{row.name}</span>,
      orderId: <TCLabel>{row.address}</TCLabel>,
    })
  }

  return (
    <>
      {/* <Button variant="contained" sx={{mb:2}} disableElevation onClick={() => {
        // window.location.href = "/courts/add"
        navigate("/courts/add")
      }}>Add New Places</Button> 
      <CourtFilter filter={filter} onChangeFilter={setFilter} /> */}
      <Grid container xs={12}>
        <Grid item xs={3}>
          <Button variant="contained" sx={{mb:2}} disableElevation onClick={() => {
            // window.location.href = "/courts/add"
            navigate("/courts/add")
          }}>Add New Places</Button>
        </Grid>
        <Grid item xs>
          <CourtFilter filter={filter} onChangeFilter={setFilter} />
        </Grid>
      </Grid>
      <TableComponent
        columns={[
          { label: "Place Name" },
          { label: "Address" },
        ]}
        rows={rows}
        valueCustomator={valueCustomator}
      />
    </>
  )
}

export default Courts;
