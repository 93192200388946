import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { getPlayers } from "services/playerServices";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { activeOption, availableOption, discountType, orderStatusOption, orderTypeOption } from "constant/constant";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import { TCSelectStyle } from "components/TopCourtComponents/Input/TopCourtInput";

export default function VoucherFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {
    const loadUserOption = async (input) => {
        if (input.length < 3) {
            return
        }
        try {
            let { data, error } = await getPlayers({
                name: input
            });
            if (error) throw error
            if (data) {
                if (data.length <= 0) return
                return data.map(value => {
                    return {
                        label: value.name,
                        value: value._id
                    }
                })
            }
        } catch (error) {
            console.log(error.message || error)
            return ([])
        }
    }

    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput placeHolder={'Voucher'} onChange={(e) => onChangeFilter({ voucherCode: e.currentTarget.value })} value={filter.voucherCode} label={"Voucher Code"} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCLabel style={{fontSize: 13, color: "rgb(133, 133, 133)"}}>Used By</TCLabel>
                        <AsyncSelect
                            styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                ...TCSelectStyle,
                            }}
                            placeholder={'Select User...'}
                            isClearable
                            async
                            defaultOptions
                            loadOptions={loadUserOption}
                            value={filter.usedBy} onChange={(e) => { onChangeFilter({ usedBy: e }) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ mb: 1 }}>
                        <DateRangeInput
                            showLabel={true}
                            label={'Expiry Date'}
                            startDate={filter.expiryStartDate}
                            endDate={filter.expiryEndDate}
                            dateRangeType={filter.expiryDateType}
                            onChangeSelect={(e)=>onChangeFilter({ expiryDateType: e })}
                            onChangeStartDate={(e)=>onChangeFilter({ expiryStartDate: e })}
                            onChangeEndDate={(e)=>onChangeFilter({ expiryEndDate: e })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Status'} options={availableOption} isClearable={true} value={filter.status} onChange={(e) => {
                            onChangeFilter({ status: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1, textAlign: 'right' }} >
                        <Button sx={{mt: 2, border: '0.5px solid #2196f3', borderRadius: 4}}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
