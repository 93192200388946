import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { Confirm } from "helper/Alert";
import { getPlayerSession } from "services/playerServices";
import TableComponent from "components/TopCourtComponents/Table/TableComponent";
import moment from "moment";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import { DBCR } from "constant/constant";

const initFilter = {
    startDate: null,
    endDate: null,
    dbcr: null,
    page: 0
}

function PlayerSession({
    playerId
}) {

    // load session history
    const [rows, setRows] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [filter, setFilter] = useState(initFilter)

    useEffect(() => {
        loadSession()
    }, [filter])


    const loadSession = async () => {
        try {
            // session-balance
            console.log(filter)
            let { data, error } = await getPlayerSession({
                id: playerId,
                dateType: 'ib',
                startDate: filter.startDate,
                endDate: filter.endDate,
                entry: filter.dbcr ? filter.dbcr.value : '',
                page: filter.page ? filter.page : 0,
                limit: filter.limit ? filter.limit : 20
            })
            if (error) { throw error }
            if (data) {
                let { balanceSession, totalData } = data
                setRows(balanceSession)
                setTotalData(totalData)
            }
        } catch (error) {
            console.log(error)
            Confirm("", error)
        }
    }
    const clearFilter = () => {
        setFilter(initFilter)
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const onChangePage = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const valueCustomator = (e) => {
        return ({
            date: <TCLabel>{moment(e.createdDate).format("YYYY/MM/DD HH:mm")}</TCLabel>,
            dbcr: <TCLabel>{e.entry}</TCLabel>,
            transaction: <TCLabel>{
                e.title == "Voucher Redeem" ? "Top Up via Voucher" :
                    e.title == "Value Pack" ? "Top Up via Value Pack" : e.title
            }</TCLabel>,
            // admin
            order: <TCLabel>{e.bookingId ? e.bookingId : "-"}</TCLabel>,
            session: e.entry == "CR" ? <TCLabel style={{ color: '#00c853' }}>{e.value}</TCLabel> : <TCLabel style={{ color: "#f44336" }}>{e.value}</TCLabel>
        })
    }

    return (
        <Grid container xs={12}>
            {/* <Grid item container xs={12}>
                <TCLabel>{"Total Session"}</TCLabel>
            </Grid> */}
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={5} md={3} sx={{ minWidth: '270px' }} >
                    <DateRangeInput showLabel={true} label={"Date"}
                        disabledVariable
                        startDate={filter.startDate}
                        endDate={filter.endDate}
                        onChangeStartDate={(date, load) => { onChangeFilter({ startDate: date, load }) }}
                        onChangeEndDate={(date, load) => { onChangeFilter({ endDate: date, load }) }}
                    />
                </Grid>
                <Grid item xs={5} md={3} style={{ minWidth: '250px' }} sx={{ minWidth: '120px' }} >
                    <TCSelect label={"DB/CR"} options={DBCR} value={filter.dbcr} onChange={(e) => onChangeFilter({ dbcr: e })} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ mb: 1, textAlign: 'right' }} >
                    <Button sx={{ mt: 2, border: '0.5px solid #2196f3', borderRadius: 4 }}
                        onClick={clearFilter}
                    >Clear</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {/* example table here */}
                <TableComponent
                    columns={[
                        { label: "Date" },
                        { label: "DB / CR" },
                        { label: "Transaction" },
                        // {label: ""},
                        { label: "Order" },
                        { label: "Session" },
                    ]}
                    rows={rows}
                    totalData={totalData}
                    page={filter.page}
                    valueCustomator={valueCustomator}
                    pageChange={(e) => { onChangePage({page: e}) }}
                />
            </Grid>
        </Grid>
    )
}

export default PlayerSession