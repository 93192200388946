import React, { useState, useEffect } from "react"

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button, Switch, Dialog } from '@mui/material';

import { currencyFormat } from 'helper/NumberFormat';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import { getAdminList, updateAdminStatus } from "services/adminServices";
import moment from "moment";
import TableComponent from "components/TopCourtComponents/Table/TableComponent";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AdminFilter from './adminFilter';
import { capitalizeFirstLetter } from "helper/String";
import { roleOption } from "constant/constant";
import { useNavigate } from "react-router";

const initFilter = {
    searchQuery: ''
}
const initDialog = {
    open: false,
    data: null,
    actionConfirm: null,
    actionCancel: null
}

function Admins({

}) {
    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState(initFilter)
    const [dialog, setDialog] = useState(initDialog)
    const navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, [filter])

    const loadData = async () => {
        let { data, error } = await getAdminList({
            searchQuery: filter.searchQuery ? filter.searchQuery : ""
        });
        if (!data) { return; }
        setRows(data)
    }

    const changeAdminStatus = async (isActive) => {
        let { data, error } = await updateAdminStatus({
            _id: dialog.data._id,
            isActive: isActive
        });
        if (!data) { return; }
        if (data) {
            setDialog(initDialog)
            loadData()
        }
    }

    const valueCustomator = (e) => {
        return ({
            username: <span className='span-link' onClick={() => { navigate(`/admins/edit/${e._id}`) }}>{e.username}</span>,
            name: <TCLabel>{e.name}</TCLabel>,
            role: <TCLabel>{e.role ?
                roleOption.map(value => {
                    if (value.value === e.role) {
                        return value.label
                    }
                })
                :
                '-'}
            </TCLabel>,
            phoneNumber: <TCLabel>{e.phoneNumber ? e.phoneNumber : '-'}</TCLabel>,
            createdDate: <TCLabel>{moment(e.createdDate).format('YYYY/MM/DD HH:mm:ss')}</TCLabel>,
            isActive: <Switch onClick={() => onChangeDialog({ open: true, data: e })} checked={e.isActive} />,
        })
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const onChangeDialog = (e) => {
        setDialog(prev => ({
            ...prev,
            ...e
        }))
    }

    return (
        <>
            <Button variant="contained" style={{ marginBottom: 5 }} disableElevation onClick={() => {
                navigate("/admins/add")
            }}>Create Admin</Button>
            <AdminFilter filter={filter} onChangeFilter={onChangeFilter} />
            <TableComponent
                columns={[
                    { label: "Username" },
                    { label: "Name" },
                    { label: "Role" },
                    { label: "Reg Phone Number" },
                    { label: "Created Date" },
                    { label: "Status" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
            />
            <Dialog open={dialog.open} onClose={() => setDialog(initDialog)}>
                {dialog && dialog.data &&
                    <Card>
                        <Grid p={2}>
                            <TCLabel bold={true}>{dialog.data.isActive ? `Set Admin ${dialog.data.username} as Inactive ?` : `Set Admin ${dialog.data.username} as Active ?`}</TCLabel>
                            <TCLabel style={{marginTop: 10}}>Name : {dialog.data.name}</TCLabel>
                            <TCLabel>Phonenumber : {dialog.data.phoneNumber}</TCLabel>
                            <TCLabel>Role : {capitalizeFirstLetter(dialog.data.role)}</TCLabel>
                        </Grid>
                        <Grid container xs={12} spacing={1} p={1} justifyContent={"right"}>
                            <Grid item>
                                <Button onClick={() => {
                                    setDialog(initDialog)
                                }}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' style={{
                                    backgroundColor: dialog.data.isActive ? '#0198E1' : '#0198E1'
                                }} onClick={() => changeAdminStatus(!dialog.data.isActive)} >{dialog.data.isActive ? "Confirm" : "Confirm"}</Button>
                            </Grid>
                        </Grid>
                    </Card>
                }
            </Dialog>
        </>
    )
}

export default Admins